<template>
  <b-overlay :show="quizLoading">
    <div class="quiz_view" v-if="quiz && quiz.id">
      <BreadCrumb :items="breadCrumb">
        <template v-slot:controls>
          <button class="btn btn-sm btn-success" @click="$router.push({name:'quiz-new', params:{book_id}})" title="Add New Quiz"><AddNewIcon /> Add New Quiz</button>
        </template>
      </BreadCrumb>
      <b-card footer-class="text-right">
        <QuizPreview :quiz="quiz" />
        <template v-slot:footer>
          <button class="btn btn-sm btn-primary ml-2" :disabled="!quiz" @click="$router.push({name:'quiz-edit', params:{quiz_id}})" title="Edit this quiz">Edit</button>
          <button class="btn btn-sm btn-danger ml-2" :disabled="!quiz" @click="deleteBook" title="Delete this book">Delete</button>
        </template>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import AddNewIcon from 'mdi-vue/PlaylistPlus';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import getQuizMixin from '@/mixins/getquiz';
import QuizPreview from './components/QuizPreview.vue';

export default {
  mixins: [getQuizMixin],
  components: { BreadCrumb, AddNewIcon, QuizPreview },

  data() {
    return {
      quizLoading: false,
      quiz_id: this.$route.params.quiz_id,
      book_id: this.$route.params.book_id,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id, to: { name: 'bke-book-view', params: { book_id: this.book_id } } },
        { text: 'Quizzes List', to: { name: 'quiz-list', params: { book_id: this.book_id } } },
        { text: this.quiz_id || 'View', active: true },
      ];
    },
  },

  methods: {
    deleteBook() {
      this.$bvModal.msgBoxConfirm('This quiz will be deleted. Are you sure?', {
        buttonSize: 'sm', centered: true, okVariant: 'danger', okTitle: 'Yes', cancelTitle: 'No',
      })
        .then((reply) => {
          if (reply === true) {
            this.quizLoading = true;
            this.$store.dispatch('quiz/deleteQuizzes', { bookId: this.book_id, quizIds: [this.quiz_id] })
              .then(() => {
                this.$router.push({ name: 'bke-book-list' });
              }).catch(() => {
                this.$toast.error('Failed to delete selected quiz. Try again later.');
              }).finally(() => {
                this.quizLoading = false;
              });
          }
        })
        .catch(() => { /* ignore */ });
    },
  },

};
</script>
